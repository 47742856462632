import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGaurd } from './auth-guard.service';
import { VerifyPasscodeComponent } from './auth/verify-passcode/verify-passcode.component';
import { UnderDevelopmentComponent } from './shared/components/under-development/under-development.component';
import { InvalidLoginComponent } from './invalid-login/invalid-login.component';
import { AdmissionTestGuardResolver } from './admissionTestGuard.resolver';
import { ROUTES as ON_SPOT_ROUTES } from './on-spot-test/on-spot-test.constants';
import { LoginGuard } from './login-guard.service';
import { ROUTES_LINK } from './app.constants';

const routes: Routes = [
  {
    path: '',
    canActivate: [LoginGuard],
    loadChildren: () => import('src/app/home-info/home-info.module').then(m => m.HomeInfoModule)
  },
  {
    path: 'parent-proforma/:userId',
    component: VerifyPasscodeComponent,
    resolve: {
      resolvedData: AdmissionTestGuardResolver,
    },
  },
  {
    path: 'teacher-proforma/:userId',
    component: VerifyPasscodeComponent,
    resolve: {
      resolvedData: AdmissionTestGuardResolver,
    },
  },
  {
    path: 'foreward-and-FUPs',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/foreward-fup/foreward-fup.module').then(m => m.ForewardFupModule),
    resolve: {
      resolvedData: AdmissionTestGuardResolver,
    },
  },
  {
    path: 'complete-profile',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/onboarding/onboarding.module').then(m => m.OnboardingModule),
    resolve: {
      resolvedData: AdmissionTestGuardResolver,
    },
  },
  {
    path: 'profile',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/profile/profile.module').then(m => m.ProfileModule),
    resolve: {
      resolvedData: AdmissionTestGuardResolver,
    },
  },
  // {
  //   path: 'refer-and-earn',
  //   loadChildren: () => import('src/app/refer-and-earn/refer-and-earn.module').then(m => m.ReferAndEarn)
  // },
  {
    path: 'home',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/home-page/home-page.module').then(m => m.HomePageModule),
    resolve: {
      resolvedData: AdmissionTestGuardResolver,
    },
  },
  {
    path: 'cart',
    loadChildren: () => import('src/app/cart/cart.module').then(m => m.CartModule)
  },
  {
    path: 'view-schedule/:packageId',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/view-schedule/view-schedule.module').then(m => m.ViewScheduleModule),
    resolve: {
      resolvedData: AdmissionTestGuardResolver,
    },
  },
  {
    path: 'community',
    loadChildren: () => import('src/app/new-community/new-community.module').then(m => m.NewCommunityModule),
    resolve: {
      resolvedData: AdmissionTestGuardResolver,
    },
  },
  {
    path: 'my-revision-plan',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/myRevision-plan/myRevision-plan.module').then(m => m.MyRevisionPlanModule),
    resolve: {
      resolvedData: AdmissionTestGuardResolver,
    },
  },
  {
    path: 'test',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/test/test.module').then(m => m.TestModule),
    resolve: {
      resolvedData: AdmissionTestGuardResolver,
    },
  },
  // Routes for new result analysis
  {
    path: 'test-results',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/test-results-analytics/test-results-analytics.module').then(m => m.TestResultsAnalyticsModule),
    resolve: {
      resolvedData: AdmissionTestGuardResolver,
    },
  },
  {
    path: 'test-results/:testId/:attemptId/:testType',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/test-results-analytics/test-results-analytics.module').then(m => m.TestResultsAnalyticsModule),
    resolve: {
      resolvedData: AdmissionTestGuardResolver,
    },
  },
  {
    path: 'test-results/:testId/:attemptId/:testType/:testName',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/test-results-analytics/test-results-analytics.module').then(m => m.TestResultsAnalyticsModule),
    resolve: {
      resolvedData: AdmissionTestGuardResolver,
    },
  },
  {
    path: 'test-results/:testId/:attemptId/:testType/:testName/:authToken',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/test-results-analytics/test-results-analytics.module').then(m => m.TestResultsAnalyticsModule),
    resolve: {
      resolvedData: AdmissionTestGuardResolver,
    },
  },
  {
    path: 'test-results/:testId/:attemptId/:testType/:courseId',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/test-results-analytics/test-results-analytics.module').then(m => m.TestResultsAnalyticsModule),
    resolve: {
      resolvedData: AdmissionTestGuardResolver,
    },
  },
  {
    path: 'test-results/:testId/:attemptId/:testType/:testName/:courseId/:authToken',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/test-results-analytics/test-results-analytics.module').then(m => m.TestResultsAnalyticsModule),
    resolve: {
      resolvedData: AdmissionTestGuardResolver,
    },
  },
  {
    path: ':module/test-results',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/test-results-analytics/test-results-analytics.module').then(m => m.TestResultsAnalyticsModule)
  },
  {
    path: ':module/test-results/:testId/:attemptId/:testType',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/test-results-analytics/test-results-analytics.module').then(m => m.TestResultsAnalyticsModule)
  },
  {
    path: ':module/test-results/:testId/:attemptId/:testType/:testName',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/test-results-analytics/test-results-analytics.module').then(m => m.TestResultsAnalyticsModule)
  },
  {
    path: ':module/test-results/:testId/:attemptId/:testType/:testName/:authToken',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/test-results-analytics/test-results-analytics.module').then(m => m.TestResultsAnalyticsModule)
  },
  {
    path: ':module/test-results/:testId/:attemptId/:testType/:courseId',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/test-results-analytics/test-results-analytics.module').then(m => m.TestResultsAnalyticsModule)
  },
  {
    path: ':module/test-results/:testId/:attemptId/:testType/:testName/:courseId/:authToken',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/test-results-analytics/test-results-analytics.module').then(m => m.TestResultsAnalyticsModule)
  },
  {
    path: 'revision/question-details',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/revision-question-details/revision-question-details.module').then(m => m.RevisionQuestionDetailsModule),
    resolve: {
      resolvedData: AdmissionTestGuardResolver,
    },
  },
  // Routes for new result analysis ends

  { // TODO: insert in some module later
    path: 'dashboard',
    canActivate: [AuthGaurd],
    component: UnderDevelopmentComponent,
    resolve: {
      resolvedData: AdmissionTestGuardResolver,
    },
  },
  {
    path: 'test-details/course/:courseId/test/:testId/package/:packageId',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/test-details/test-details.module').then(m => m.TestDetailsModule),
    resolve: {
      resolvedData: AdmissionTestGuardResolver,
    },
  },
  {
    path: 'test-details/course/:courseId/test/:testId/:isCustomTest',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/test-details/test-details.module').then(m => m.TestDetailsModule),
    resolve: {
      resolvedData: AdmissionTestGuardResolver,
    },
  },
  {
    path: 'test-details/course/:courseId/test/:testId',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/test-details/test-details.module').then(m => m.TestDetailsModule),
    resolve: {
      resolvedData: AdmissionTestGuardResolver,
    },
  },
  {
    path: 'test-details/course/:courseId/assignment/:assignmentId/test/:testId',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/test-details/test-details.module').then(m => m.TestDetailsModule),
    resolve: {
      resolvedData: AdmissionTestGuardResolver,
    },
  },
  {
    path: ':module/test-details/course/:courseId/test/:testId/package/:packageId',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/test-details/test-details.module').then(m => m.TestDetailsModule)
  },
  {
    path: ':module/test-details/course/:courseId/test/:testId/:isCustomTest',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/test-details/test-details.module').then(m => m.TestDetailsModule)
  },
  {
    path: ':module/test-details/course/:courseId/test/:testId',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/test-details/test-details.module').then(m => m.TestDetailsModule)
  },
  {
    path: ':module/test-details/course/:courseId/assignment/:assignmentId/test/:testId',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/test-details/test-details.module').then(m => m.TestDetailsModule)
  },
  {
    path: 'cpp-subjective-file-upload',
    loadChildren: () => import('src/app/upload-file-subjective/upload-file-subjective.module').then(m => m.UploadFileSubjectiveModule),
    resolve: {
      resolvedData: AdmissionTestGuardResolver,
    },
  },
  {
    path: 'about-us',
    loadChildren: () => import('src/app/about-us/about-us.module').then(m => m.aboutUsModule),
    resolve: {
      resolvedData: AdmissionTestGuardResolver,
    },
  },
  {
    path: 'm.about-us',
    loadChildren: () => import('src/app/about-us-mobile/about-us-mobile.module').then(m => m.AboutUsMobileModule),
    resolve: {
      resolvedData: AdmissionTestGuardResolver,
    },
  },
  {
    path: 'terms-of-use',
    loadChildren: () => import('src/app/terms-of-use/terms-of-use.module').then(m => m.TermsOfUseModule),
    resolve: {
      resolvedData: AdmissionTestGuardResolver,
    },
  },
  {
    path: 'm.terms-of-use',
    loadChildren: () => import('src/app/terms-of-use-mobile/terms-of-use-mobile.module').then(m => m.TermsOfUseMobileModule),
    resolve: {
      resolvedData: AdmissionTestGuardResolver,
    },
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('src/app/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule),
    resolve: {
      resolvedData: AdmissionTestGuardResolver,
    },
  },
  {
    path: 'm.privacy-policy',
    loadChildren: () => import('src/app/privacy-policy-mobile/privacy-policy-mobile.module').then(m => m.PrivacyPolicyMobileModule),
    resolve: {
      resolvedData: AdmissionTestGuardResolver,
    },
  },
  {
    path: 'copyright',
    loadChildren: () => import('src/app/copyright/copyright.module').then(m => m.CopyrightModule),
    resolve: {
      resolvedData: AdmissionTestGuardResolver,
    },
  },
  {
    path: 'disclaimer',
    loadChildren: () => import('src/app/disclaimer/disclaimer.module').then(m => m.DisclaimerModule),
    resolve: {
      resolvedData: AdmissionTestGuardResolver,
    },
  },
  {
    path: 'fiitjee-recorded-lectures-privacy-policy',
    loadChildren: () => import('src/app/lecture-privacy-policy/lecture-privacy-policy.module').then(m => m.LecturePrivacyPolicyModule),
    resolve: {
      resolvedData: AdmissionTestGuardResolver,
    },
  },
  {
    path: 'm.fiitjee-recorded-lectures-privacy-policy',
    loadChildren: () => import('src/app/mobile-lecture-privacy-policy/mobile-lecture-privacy-policy.module').then(m => m.MobileLecturePrivacyPolicyModule),
    resolve: {
      resolvedData: AdmissionTestGuardResolver,
    },
  },
  // {
  //   path: 'help',
  //   canActivate: [AuthGaurd],
  //   loadChildren: () => import('src/app/help-and-support/help-and-support.module').then(m => m.HelpAndSupportModule)
  // },
  // {
  //   path: 'idea',
  //   canActivate: [AuthGaurd],
  //   loadChildren: () => import('src/app/idea-and-support/idea-and-support.module').then(m => m.IdeaAndSupportModule)
  // },
  {
    path: 'checkout',
    canActivate: [AuthGaurd],
    loadChildren: () => import('src/app/checkout/checkout.module').then(m => m.CheckoutModule),
    resolve: {
      resolvedData: AdmissionTestGuardResolver,
    },
  },
  {
    path: 'concept-history/:syllabusId/:conceptId/:title',
    canActivate: [AuthGaurd],
    resolve: {
      resolvedData: AdmissionTestGuardResolver,
    },
    loadChildren: () => import('src/app/concept-history/concept-history.module').then(m => m.ConceptHistoryModule)
  },
  {
    path: 'competitive-exam',
    loadChildren: () => import('src/app/landing-pages/competitive-exam/competitive-exam.module').then(m => m.CompetitiveExamModule)
  },
  {
    path: 'view-our-results',
    loadChildren: () => import('src/app/landing-pages/view-our-results/view-our-results.module').then(m => m.ViewOurResultsModule)
  },
  {
    path: 'exam/:id',
    loadChildren: () => import('src/app/landing-pages/exam/exam.module').then(m => m.ExamModule)
  },
  {
    path: 'ebooks',
    loadChildren: () => import('src/app/landing-pages/ebooks/ebooks.module').then(m => m.EbooksModule)
  },
  {
    path: 'previous-year-paper-download',
    loadChildren: () => import('src/app/previous-year-papers-download/previous-year-papers-download.module').then(m => m.PreviousYearPapersDownloadModule)
  },
  {
    path: 'user',
    loadChildren: () => import('src/app/login-signup-info/login-signup-data.module').then(m => m.LoginSignupDataModule)
  },
  {
    path: 'prep-tracker',
    loadChildren: () => import('src/app/landing-pages/prep-tracker/prep-tracker.module').then(m => m.PrepTrackerModule)
  },
  {
    path: 'college-predictor',
    loadChildren: () => import('src/app/college-predictor/college-predictor.module').then(m => m.CollegePredictorModule)
  },
  {
    path: 'quiz',
    loadChildren: () => import('src/app/quiz-platform/quiz-platform.module').then(m => m.QuizPlatformModule)
  },
  {
    path: ROUTES_LINK.ACCOUNT_DELETION,
    loadChildren: () => import('src/app/account-deletion/account-deletion.module').then(m => m.AccountDeletionModule)
  },
  {
    path: ON_SPOT_ROUTES.ON_SPOT,
    loadChildren: () => import('src/app/on-spot-test/on-spot-test.module').then(m => m.OnSpotTestModule),
    resolve: {
      resolvedData: AdmissionTestGuardResolver,
    },
  }, 
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})

export class AppRoutingModule { }
