export const USER_TYPE = {
    STUDENTS: 'students',
    STAKEHOLDER: 'stakeholders',
    STAFF: 'staff',
    STAFF_ALUMNI: 'staffalumni',
    SCHOOLS: 'schools',
    STUDENT_ALUMNI: 'studentalumni',
    FRESH_REGISTRATION: 'freshregistrations',
    PARENTS: 'parents',
    ALUMNI_PARENTS: 'alumni_and_parents',
    STUDENT_6: 'studentsclass6',
    STUDENT_7: 'studentsclass7',
    STUDENT_8: 'studentsclass8',
    STUDENT_9: 'studentsclass9',
    STUDENT_10: 'studentsclass10',
    STUDENT_11: 'studentsclass11',
    STUDENT_12: 'studentsclass12',
    ADVTSEPT_2: 'advtsept2',
    FACULTY_RECRUITMENT: 'facultyrecruitment',
    ADVTSEPT_15: 'advtsept15',
};

export const USER_TYPE_DISPLAY_NAME = {
    [USER_TYPE.STUDENTS]: 'Students',
    [USER_TYPE.STAKEHOLDER]: 'Stakeholders',
    [USER_TYPE.STAFF]: 'Staff',
    [USER_TYPE.SCHOOLS]: 'Schools',
    [USER_TYPE.STAFF_ALUMNI]: 'Staff Alumni',
    [USER_TYPE.STUDENT_ALUMNI]: 'Old Students',
    [USER_TYPE.FRESH_REGISTRATION]: 'Fresh Registration',
    [USER_TYPE.PARENTS]: 'Parents',
    [USER_TYPE.ALUMNI_PARENTS]: 'Alumni and Parents',
    [USER_TYPE.STUDENT_6]:'Students Class 6',
    [USER_TYPE.STUDENT_7]:'Students Class 7',
    [USER_TYPE.STUDENT_8]:'Students Class 8',
    [USER_TYPE.STUDENT_9]:'Students Class 9',
    [USER_TYPE.STUDENT_10]:'Students Class 10',
    [USER_TYPE.STUDENT_11]:'Students Class 11',
    [USER_TYPE.STUDENT_12]:'Students Class 12',
    [USER_TYPE.FACULTY_RECRUITMENT]: 'Faculty Recruitment',
};

export const GENERIC_CONST = {
    OTP_EXPIRY_INTERVAL: 60,
    SURVEY_TYPE: 'survey',
    MASTER_OTP: '9999',
    PAUSE: 'pause',
    RESUME: 'resume',
    GRACE: 'grace',
    TIME_LEFT_GRACE: 10,
    MIN_ENROLMENT_NO_LENGTH: 13,
    QUIZ: 'quiz',
    TEST: 'test',
}

export const QUESTION_TYPE = {
    SMCQ: 0,
    MATRIX: 1,
    DESCRIPTIVE: 4,
    NUMERICAL: 6,
    INTEGER: 7,
    MMCQ: 8
}

export const SURVEY_TYPE = {
    LONG: 'long',
    SHORT: 'short',
    ANSWERS_ONLY: 'answers-only',
    STUDENT: 'student',
    TEACHER: 'teacher',
    PARENT: 'parent',
    OTHERS: 'others',
    SURVEY_FORWARD: 'survey-forward',
    SURVEY_COUNT: 'survey-count',
}

export const RATING_QUESTION_CLASS = ['minVal', 'midVal', 'maxVal'];
export const NUMERICAL_REGEX = /^[-]?\d*\.?\d*$/;

export const QUIZ_STATUS_NOT_FINISHED = 'notFinished';

export const SHOW_CLASS_FILTER = ['student', 'parent'];

export const FACULTY_RECRUITMENT: any = {
    ROUNDS_STATUS: {
        UPCOMING: 'upcoming',
        START: 'start',
        RESUME: 'resume',
        IN_REVIEW: 'inReview',
        SELECTED: 'selected',
        NOT_SELECTED: 'notSelected',
    },
    ROUNDS_TYPE: {
        'Online Assessment': {
            TEXT: 'Online Assessment',
            IMG: 'online-assessment-round',
        },
        'Offline Assessment': {
            CLASS: 'offline',
            IMG: 'offline-assessment-round',
            TEXT: 'Offline Assessment',
        },
        'In Person Interview': {
            CLASS: 'interview',
            IMG: 'inperson-interview',
            TEXT: 'In Person Interview',
        },
    },
    ROUND_STATUS_DETAILS: {
        upcoming: {
            CTA_TEXT: 'Upcoming',
        },
        start: {
            CTA_TEXT: "Lets's Start",
        },
        resume: {
            CTA_TEXT: 'Resume',
        },
        inReview: {
            CTA_TEXT: 'In Review',
        },
        selected: {
            CTA_TEXT: 'Selected',
        },
        notSelected: {
            CTA_TEXT: 'Not Selected',
        },
    },
    TEST_STATUS: {
        FINISHED: 'finished',
    },
    MESSAGE_IMAGE: {
        inReview: 'inreview-message',
        selected: 'green-up',
        notSelected: 'red-down',
    }
};

export const SOURCE: any = {
    MYPAT: 'quiz.mypat.in',
    FACULTY_RECRUITMENT: 'facultyrecruitment.edfora.com',
};

export const USER_SELFIE_IMAGE_NAME = 'faculty-recruitment-user-selfie';
export const NUMERIC_QUESTION_TYPE_KEYPAD = [
    ['7', '8', '9'],
    ['4', '5', '6'],
    ['1', '2', '3'],
    ['0', '.', '-'],
    ['←', '→']
];

export const QUIZ_DATE_WISE = ['advtsept2', 'advtsept15']
