import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { catchError, map, timeout } from 'rxjs/operators';
import { AlertService } from './alert.service';
import { Store } from '@ngrx/store';
import * as fromApp from '../../stores/app.reducers';
import * as LoaderAction from '../../stores/loader/loader.actions';
import * as AuthActions from '../../stores/auth/auth.actions';

declare var $: any;
@Injectable({
  providedIn: 'root'
})
export class HttpService {
  public serverName = environment.serverURLv4;
  url: string;
  accessToken1: Observable<any>;
  accessToken: string;
  authenticated;
  selectedGoal;
  public timeoutVar: any;
  promise: Promise<any>;
  public apiFailedForHomeCards = new BehaviorSubject<any>(null);
  public finishingSubjective = new BehaviorSubject<any>(null);
  constructor(
    private httpService: HttpClient, private router: Router, private alert: AlertService,
    private store: Store<fromApp.AppState>) {
    this.store.select('auth').subscribe((data) => {
      this.accessToken = 'JWT ' + data.token;
      this.authenticated = data.authenticated;
    });
  }

  public get authToken(){
    return localStorage.getItem('fiitjeeToken');
  }

  parseServerError(error): string {
    this.finishingSubjective.subscribe((res) => {
      if (res && res.finishing === 'true') {
        this.finishingSubjective.next({'finishing' : 'networkError'});
      }
    });
    try {
      // error = error.json();
      const message = error.message || 'Something went wrong! Please try again.';
      return message;
    } catch (e) {
      return 'Something went wrong.';
    }
  }

  handleError = (error: any): Observable<any> => {
    try {
      let errormsg;
      if (error.url.includes('v4/auth/my-plan/syllabus') || error.url.includes('v4/auth/users/courses')) {
        this.apiFailedForHomeCards.next(true);
      }
      this.store.dispatch(new LoaderAction.SetLoader(false));
      switch (error.status) {
        case 400:
        case 401:
          // Unauthorised
          $('#logoutModal').modal('hide');
          $('.modal-backdrop').remove();
          $('body').removeClass('modal-open');
          this.store.dispatch(new AuthActions.SetToken(''));
          errormsg = this.parseServerError(error.error);
          this.alert.showErrorMessage(this.parseServerError(error.error));
          // tslint:disable-next-line: deprecation
          localStorage.clear();
          sessionStorage.clear();
          this.router.navigate(['/']);
          return throwError(errormsg);
        case 404: {
          this.alert.showErrorMessage(this.parseServerError(error.error));
          // tslint:disable-next-line: deprecation
          return throwError('Server error');
        }
        case 500:
          if (error && error.error && error.error.message === 'jwt must be provided') {
            this.store.dispatch(new AuthActions.SetToken(''));
            localStorage.clear();
            sessionStorage.clear();
            this.router.navigate(['/']);
          } else {
            this.alert.showErrorMessage(this.parseServerError(error.error));
          }
          // tslint:disable-next-line: deprecation
          return throwError('Server error');

        default:
          errormsg = this.parseServerError(error);
          // tslint:disable-next-line: deprecation
          return throwError(error.message || errormsg);
      }
    } catch (e) {
      // tslint:disable-next-line: deprecation
      return throwError(e);
    }
  }

  // Get Method - Start
  get(apiName: string, isAuth?: any, params?: any, loader?, isGoal?, hostname?: any, isFiitjeeQuiz?: boolean): Observable<any> {
    this.url = (hostname ? hostname : this.serverName) + apiName;
    if (isAuth && !this.authToken) {
      this.isLoginRequire();
    }
    if (!navigator.onLine) {
      this.alert.showErrorMessage('Please check your internet connection');
      return throwError('Please check your internet connection');
    }
    if (loader === undefined || loader) {
      this.store.dispatch(new LoaderAction.SetLoader(true));
    }
    // This code will add access token
    // tslint:disable-next-line: deprecation
    let headers;
    if (isAuth) {
      if (apiName.includes('goal-levels-history')) {
        headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('authToken'));  
      } else if(isFiitjeeQuiz){
        headers = new HttpHeaders().set('Authorization', this.authToken);
      } else {
        headers = new HttpHeaders().set('Authorization', this.accessToken);
      }
      headers = headers.append('Content-Type', 'application/json');
    } else {
      headers = new HttpHeaders().set('Content-Type', 'application/json');
    }
    // handle parameters
    if (params) {
      if (isGoal) {
        params.goalId = this.selectedGoal.courseId;
        params.goalName = this.selectedGoal.name;
      }
    } else if (isGoal) {
      let param: any;
      param = {
        goalId: this.selectedGoal.courseId
      };
    }

    let paramsToBePassed = new HttpParams();
    for (const p in params) {
      if (p) {
        paramsToBePassed = paramsToBePassed.append(p, params[p]);
      }
    }
    const options = {
      params: paramsToBePassed,
      headers
    };

    // Create a request option
    return this.httpService.get(this.url, options).pipe(
      map((res: Response) => {
        if (loader === undefined || loader) {
          this.store.dispatch(new LoaderAction.SetLoader(false));
        }
        return res;
      }),
      catchError(this.handleError)
    );
    // ...errors if any
  }
  // Get Method - End

  // Post Method - Start
  post(apiName: string, isAuth?: any, body?: any, loader?, showAlert?, isGoal?, serverURL?, isFiitjeeQuiz?: boolean): Observable<any> {
    this.url = (serverURL ? serverURL : this.serverName) + apiName;
    if (isAuth && !this.authToken) {
      this.isLoginRequire();
    }
    if (isGoal) {
      body.goalId = this.selectedGoal.courseId;
      body.goalName = this.selectedGoal.name;
    }
    if (environment.name === 'staging') {
      this.timeoutVar = 500000;
    } else {
      this.timeoutVar = 30000;
    }
    if (!navigator.onLine) {
      this.alert.showErrorMessage('Please check your internet connection');
      return throwError('Please check your internet connection');
    }
    if (loader === undefined || loader) {
      this.store.dispatch(new LoaderAction.SetLoader(true));
    }
    let headers;
    if (isAuth) {
      if (apiName.includes('goal-levels-history')) {
        headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('authToken'));  
      } else if(isFiitjeeQuiz){
        headers = new HttpHeaders().set('Authorization', this.authToken);
      } else {
        headers = new HttpHeaders().set('Authorization', this.accessToken);
      }
      headers = headers.append('Content-Type', 'application/json');
    } else {
      headers = new HttpHeaders().set('Content-Type', 'application/json');
    }
    // This code will add access token
    return this.httpService.post(this.url, JSON.stringify(body), { headers }).pipe(
      map(
        (res: Response) => {
          if (loader === undefined || loader) {
            this.store.dispatch(new LoaderAction.SetLoader(false));
          }
          return res;
        }

      ),
      timeout(this.timeoutVar),
      catchError(this.handleError)
    );
  }
  // Post Method - End

  // Post Upload File Method - Start
  postUploadFile(dataToSend, imageData,fileName?): any {
    const formData: FormData = new FormData();
    formData.append('key', dataToSend.fields.key.toString());
    formData.append('bucket', dataToSend.fields.bucket.toString());
    formData.append('acl', 'public-read');
    formData.append('X-Amz-Algorithm', dataToSend.fields['X-Amz-Algorithm']);
    formData.append('X-Amz-Credential', dataToSend.fields['X-Amz-Credential']);
    formData.append('X-Amz-Date', dataToSend.fields['X-Amz-Date']);
    // tslint:disable-next-line: no-string-literal
    formData.append('Policy', dataToSend.fields['Policy']);
    formData.append('X-Amz-Signature', dataToSend.fields['X-Amz-Signature']);
    if(fileName){
      formData.append('file', imageData, fileName);
    }
    else{
      formData.append('file', imageData, imageData.name);
    }

    this.store.dispatch(new LoaderAction.SetLoader(true));
    const options = {
      headers: new HttpHeaders({
        Accept: 'application/json',
      }),
      observe: 'response' as 'body'  // this is for viewing complete response in case on HTTPClient
    };
    return this.httpService.post(dataToSend.url, formData, options).pipe(
      map(
        (res: Response) => {
          this.store.dispatch(new LoaderAction.SetLoader(false));
          return res;
        }
      ),
      // timeout(this.timeoutVar),
      catchError(this.handleError)
    );
  }
  // Post Upload File Method - End
  // Put Method - Start
  put(apiName: string, isAuth?: any, body?: any, loader?, hostname?,  isFiitjeeQuiz?: boolean): Observable<any> {
    // this.url = this.serverName + apiName;
    this.url = (hostname ? hostname : this.serverName) + apiName;
    if (isAuth && !this.authToken) {
      this.isLoginRequire();
    }
    if (!navigator.onLine) {
      return throwError('Please check your internet connection');
    }
    if (loader === undefined || loader) {
      this.store.dispatch(new LoaderAction.SetLoader(true));
    }
    let headers;
    if (isAuth) {
      headers = new HttpHeaders().set('Authorization', this.accessToken);
      headers = headers.append('Content-Type', 'application/json');
    }if(isFiitjeeQuiz){
      headers = new HttpHeaders().set('Authorization', this.authToken);
      headers = headers.append('Content-Type', 'application/json');
    }else {
      headers = new HttpHeaders().set('Content-Type', 'application/json');
    }
    return this.httpService.put(this.url, JSON.stringify(body), { headers }).pipe(
      map((res: Response) => {
        if (loader === undefined || loader) {
          this.store.dispatch(new LoaderAction.SetLoader(false));
        }
        return res;
      }
      ),
      catchError(this.handleError)
    );
  }
  // Put Method - End



   // Patch Method - Start
   patch(apiName: string, isAuth?: any, body?: any, loader?, hostname?): Observable<any> {
    this.url = (hostname ? hostname : this.serverName) + apiName;
    if (isAuth) {
      this.isLoginRequire();
    }
    if (!navigator.onLine) {
      return throwError('Please check your internet connection');
    }
    if (loader === undefined || loader) {
      this.store.dispatch(new LoaderAction.SetLoader(true));
    }
    let headers;
    if (isAuth) {
      if (apiName.includes('users/courses') || apiName.includes('/study-materials') ) {
        headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('authToken'));  
      } else {
        headers = new HttpHeaders().set('Authorization', this.accessToken);
      }
      headers = headers.append('Content-Type', 'application/json');
    } else {
      headers = new HttpHeaders().set('Content-Type', 'application/json');
    }
    return this.httpService.patch(this.url, JSON.stringify(body), { headers }).pipe(
      map((res: Response) => {
        if (loader === undefined || loader) {
          this.store.dispatch(new LoaderAction.SetLoader(false));
        }
        return res;
      }
      ),
      catchError(this.handleError)
    );
  }
  // patch Method - End
  // Delete Method - Start
  delete(apiName: string, params?, loader?, serverURL?): Observable<any> {
    this.isLoginRequire();
    this.url = (serverURL ? serverURL : this.serverName) + apiName;
    if (!navigator.onLine) {
      this.alert.showErrorMessage('Please check your internet connection');
      return throwError('Please check your internet connection');
    }
    // This code will add access token
    if (loader === undefined || loader) {
      this.store.dispatch(new LoaderAction.SetLoader(true));
    }
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.accessToken,
      })
    };
    // Create a request option
    return this.httpService.delete(this.url, options).pipe(
      map(
        (res: Response) => {
          if (loader === undefined || loader) {
            this.store.dispatch(new LoaderAction.SetLoader(false));
          }
          return res;
        }
      )
    );
  }
  // Delete Method - End

  isLoginRequire(isFiitjeeQuiz?: boolean): void {
    if (!(localStorage.getItem('authToken') || sessionStorage.getItem('authToken')) && !this.authenticated &&
      localStorage.getItem('loggedOut') !== 'true') {
      //  if (!this.authenticated) {
      // localStorage.setItem('lastRequested', this.router.url);
      // localStorage.setItem('previousUrl', this.router.url);
      // if (!this.detectLocationService.isFiitjeeDomain()) {
      //   this.router.navigateByUrl('auth/sign-in');
      // } else {
      this.router.navigateByUrl('/');
      // }
    }
    // else {
    //   this.store.dispatch(new LoaderAction.SetLoader(false));
    // }
  }
}
