<app-header-before-login *ngIf="!authenticated && showHeader"></app-header-before-login>

<app-header-after-login *ngIf="authenticated && showHeader"></app-header-after-login>
<app-loader></app-loader>
<router-outlet></router-outlet>
<app-alert></app-alert>
<!-- <app-connection-unstable></app-connection-unstable> -->
<!-- <app-page-not-found></app-page-not-found> -->
<!-- <app-under-development></app-under-development> -->
<app-footer-before-login *ngIf="!authenticated && showHeader"></app-footer-before-login>

<!-- <app-footer-before-login *ngIf="!authenticated && showHeader"></app-footer-before-login> -->
<div class="modal fade lite-modal lock" id="restrictmodal" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom">
        <div class="modal-content">
            <div class="modal-body">
                <div class="headSec mt-2">
                    <img src="/assets/images/dashboard-icon/lock-icon.svg" alt="Restrict Access"/>
                    <h3>Access Restricted</h3>
                </div>
                <p class="mb-4">
                    Your access to myPAT has been restricted. Please provide the details requested on your email to restore access.
                </p>
            </div>
        </div>
    </div>
  </div>
<ng-container *ngFor="let detail of examPopupDetails; let i = index">
    <app-application-survey [modalNumber]="i" [examPopupData]="detail" [numberOfPopups]="numberOfPopups"></app-application-survey>
</ng-container>


