import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, RouteConfigLoadEnd, RouteConfigLoadStart, Router, RouterModule } from '@angular/router';

import { Store } from '@ngrx/store';

import * as fromApp from './stores/app.reducers';
import * as LoaderAction from './stores/loader/loader.actions';
import * as UserProfileActions from './stores/profile/user-profile.actions';
import { DetectLocationService } from './shared/services/detect-location.service';
import * as AuthActions from './stores/auth/auth.actions';
import _ from 'lodash';
import { concatMap, filter, last, toArray } from 'rxjs/operators';
import ClevertapReact from 'clevertap-react';
import { LazyLoadingService } from './shared/services/lazy-loading.service';
import { LazyLoadFileImpl } from './shared/shared.module';
import { environment } from './../environments/environment';
import { BrowserGlobalsService } from 'browser-globals';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { UtilityService } from './shared/services/utility.service';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { GLiteUtilityService } from './shared/services/g-lite-utility.service';
import { BuyCoursesService } from './home-page/cards-detail/right-selected-card/buy-courses/buy-courses.service';
import { AlertService } from './shared/services/alert.service';
import { Subscription, from } from 'rxjs';
import { ROUTES as ON_SPOT_ROUTES } from './on-spot-test/on-spot-test.constants';
import { ROUTES } from './shared/constants/route.constant';
import { CART_WARNING } from './shared/constants/error.constants';
import { Title } from '@angular/platform-browser'
import { EDFORA_DOMAIN, EDFORA_QUIZ_DOMAIN, EDFORA_QUIZ_FAVICON, EDFORA_QUIZ_TITLE, EDFORA_QUIZ_URL, EDFORA_QUIZ_URL_STPT_15, FACULTY_RECRUITMENT } from './app.constants';
declare var $: any;

declare const gtag: Function; // <------------Important: the declartion for gtag is required!


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [{ provide: 'googleTagManagerId', useValue: environment.gtmId }]
})
export class AppComponent implements OnInit, OnDestroy {
  private baseURLMinimumLength: number = 2;
  title = 'mypat-fe-students';
  public authenticated: boolean = false;
  public showHeader: boolean = false;
  public examPopupDetails: any = [];
  public numberOfPopups: number = 0;
  private originalExamPopupDetails: any = [];
  private activeSubscriptions = new Subscription();
  private routesToShowHeader = [
    "/home",
    "/terms-of-use",
    "/privacy-policy",
    "/profile",
    "/dashboard",
    "/refer-and-earn",
    "/",
    "/community",
    "/view-schedule",
    "/concept-history",
    "/my-revision-plan",
    "/about-us",
    "/exam-temparament-questions",
    "/help",
    "/idea",
    "/live-classes",
    "/copyright",
    "/disclaimer",
    "/fiitjee-recorded-lectures-privacy-policy",
    "/cart",
    "/checkout",
    "/view-our-results",
    "/previous-year-paper-download",
    "/scholarship",
    "/competitive-exam",
    "/exam",
    "/ebooks",
    "/college-predictor",
    "/prep-tracker",
    "/all-courses"
  ];
  private screenWidth: number = 0;
  isRestrictAccess: boolean = false;

  constructor(private store: Store<fromApp.AppState>,
    private router: Router,
    private route: ActivatedRoute,
    private gtmService: GoogleTagManagerService,
    private detectLocation: DetectLocationService,
    private lazyLoadingService: LazyLoadingService,
    private utilityService: UtilityService,
    @Inject(DOCUMENT) private readonly document: Document,
    private _buyCourses: BuyCoursesService,
    private _gLiteUtilityService: GLiteUtilityService,
    private _alertService: AlertService,
    private titleService: Title,
  ) { 
    this.screenWidth = window.innerWidth || this.document.documentElement.clientWidth || this.document.body.clientWidth;
   }
   redirectMobileDevice():void{
      // if (/android/i.test(navigator.userAgent)) {
      //   window.location.href = environment['androidURL'];
      // }
      // else if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
      //   window.location.href = environment['iosURL'];
      // } 
   }


  async asyncClevertapInitialize() {
    ClevertapReact.initialize(environment.clevertapId);
    return Promise.resolve()
  }
  async asyncAddGtmToDom() {
    this.gtmService.addGtmToDom();
    return Promise.resolve();
  }
  async asyncPushTag(gtmTag) {
    this.gtmService.pushTag(gtmTag);
    return Promise.resolve();
  }
  async asyncGtmTag() {
    const a = (await import('angular-google-tag-manager')).GoogleTagManagerService;
    this.gtmService = new a(new BrowserGlobalsService, environment.gtmId);
    return Promise.resolve();
  }

  async ngOnInit() {
    const hostname = window.location.hostname;
    if (hostname.includes(EDFORA_DOMAIN)) {
      this.setTitleAndFavicon(EDFORA_QUIZ_TITLE, EDFORA_QUIZ_FAVICON);
      this.checkQuizRoutes();
    }
    await this.asyncClevertapInitialize();
    this.activeSubscriptions.add(
      this.utilityService.restrictAccessPopUp.subscribe(res => {
        if (res)
          $(`#restrictmodal`).modal('show');
      })
     );
    this.route.queryParams.subscribe(params => {
      if (!(_.isEmpty(params)) && params['authToken'] && !params['process'] && !this.router.url.includes('community')) {
        localStorage.removeItem('hideMyplanPopup');
        this.store.dispatch(new LoaderAction.SetLoader(true));
        sessionStorage.setItem('authToken', params['authToken']);
        localStorage.setItem('authToken', params['authToken']);
        this.store.dispatch(new AuthActions.SetToken(params['authToken']));
        this.store.dispatch(new AuthActions.SetAuthentication());
        this.store.dispatch(new LoaderAction.SetLoader(false));
        if (params.loginVia && params.loginVia === 'qrcode') {
          localStorage.setItem('attemptIdQRCode', params['attemptId']);
          this.router.navigate(['/cpp-subjective-file-upload']);
        } else if (params.resultData) {
          sessionStorage.setItem('teachersRedirectUrl', params.redirectUrl);
          const resultData = JSON.parse(params.resultData);
          const url = '/test-results' + '/' + resultData.testId + '/' + resultData.attemptId + '/' +
            resultData.testType + '/' + (resultData.testName || resultData.encodedTestName);
          this.router.navigateByUrl(url);
        }  else if(params.redirectUrl === 'cart' || params.redirectUrl === 'buyCourse'){
          const { courseId, classId } = params;
          this._buyCourses.addToCart({ courseId:courseId,classId:classId }).subscribe((res: any) => {
            if (res?.code === 200) {
              this._gLiteUtilityService.numberOfItemsInCart.next({ numberOfItemsInCart: this._gLiteUtilityService.cartItems.length });
              this._alertService.showSuccessMessage(res.message);
            } else {
              this._alertService.showErrorMessage(res.message);
            }
            if(params.redirectUrl === 'cart'){
              this.router.navigate(['/cart']);
            }else{
              this.router.navigate(['home/buy-course']);
            }
          })
        } else {
          if (!(params?.isAdmissionTestUser === 'true')) {
            this.router.navigate(['/home']);
          }
        }
      }
    });
    this.activeSubscriptions.add(
      this.store.select("userProfile")
        .pipe(filter((data) => !!data?.userData?.userId))
        .subscribe((data) => {
          // if (this.screenWidth <= 767 && !data?.userData?.isAdmissionTestUser) {
          //   this.redirectMobileDevice();
          // }
        })
    );
    let currentUrl = ['https://quiz.mypat.in/', 'https://quiz.mypat.in', 'https://quiz.edfora.com',
      'https://facultyrecruitment-test.edfora.com', 'https://facultyrecruitment.edfora.com'];
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(async (event: NavigationEnd) => {
      const gtmTag: any = {
        event: 'page_view',
        pageName: window.location.href,
        page_path: window.location.href,
        pagePath: window.location.href,
      };
      if (window.localStorage.getItem('enrollmentNo')) {
        gtmTag.userId = window.localStorage.getItem('enrollmentNo');
      }
      if(currentUrl.includes(window.location.href)){
        this.router.navigate(['quiz']);
      } else if (!window.location.href.includes('/quiz')) {
        await this.asyncPushTag(gtmTag);
      }
    })
    if (!currentUrl.includes(window.location.href) && !window.location.href.includes('/quiz')) {
      await this.asyncAddGtmToDom();
    }
    localStorage.removeItem('checkedConceptHistory');
    localStorage.removeItem('clickedConceptHistory');
    this.store.select('auth').subscribe((data) => {
      if (data.authenticated && (localStorage.getItem('authToken') || sessionStorage.getItem('authToken'))) {
        const courseAdded = JSON.parse(localStorage.getItem('userCart')) || [];
        if (courseAdded?.length) {
          setTimeout(() => {
            this.addCourseToCart(courseAdded);
          }, 200);
        }
        this.authenticated = data.authenticated;
        const payload = {
          fromScreen: 'refresh',
          authToken: localStorage.getItem('authToken') || sessionStorage.getItem('authToken'),
          tag: ''
        };
        if (window.location.href.includes('teacher-proforma') || window.location.href.includes('parent-proforma')) {
          if (window.location.href.includes('goal-setting')) {
            payload.tag = (window.location.href.includes('teacher-proforma') ? 'teacher' : 'parent')
            // setTimeout(() => {
            this.store.dispatch(new UserProfileActions.GetUserDetail(payload));
            // }, 500);
          }
        } else {
          // setTimeout(() => {
          this.store.dispatch(new UserProfileActions.GetUserDetail(payload));
          // }, 500);
        }
      } else {
        this.authenticated = false;
      }
    });
    this.router.events.subscribe((event) => {
      if (event instanceof RouteConfigLoadStart) {
        this.store.dispatch(new LoaderAction.SetLoader(true));
      } else if (event instanceof RouteConfigLoadEnd) {
        this.store.dispatch(new LoaderAction.SetLoader(false));
      } else if (event instanceof NavigationEnd) {
        this.store.dispatch(new LoaderAction.SetLoader(false));
        this.checkHeaderCondition(event);
        this.checkFooterCondition(event);
        // if (this.detectLocation.isFiitjeeDomain()) {
        //   this.router.navigate(['/auth/sign-in']);
        // }
      } else if (event instanceof NavigationCancel) {
        this.store.dispatch(new LoaderAction.SetLoader(false));
        this.checkHeaderCondition(event);
        this.checkFooterCondition(event);
      } else if (event instanceof NavigationError) {
        if (this.authenticated) {
          this.router.navigate([ON_SPOT_ROUTES.ON_SPOT]);
        }
      }
    });
    if (!hostname.includes(EDFORA_DOMAIN)) {
      this.detectLocation.getMyCurrentLocation();
    }
    this.activeSubscriptions.add(
      this.utilityService.showApplicationSurveyPopup.subscribe((isB2C: boolean) => {
        isB2C && this.updateExamPopupDetails();
      })
    );
  }

  private checkQuizRoutes() {
    this.activeSubscriptions.add(
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          const hostname = window.location.hostname;
          const currentUrl = event.urlAfterRedirects;
          if (hostname?.includes(FACULTY_RECRUITMENT) && !currentUrl.includes('/quiz')) {
            this.router.navigate(['quiz/facultyrecruitment']);
          } else if (!currentUrl.includes('/quiz')) {
            this.router.navigate([EDFORA_QUIZ_URL_STPT_15]); // Default URL open 15 sept quiz
          }
        }
      })
    );
  }
  private setTitleAndFavicon(title: string, faviconUrl: string) {
    this.titleService.setTitle(title);
    const link: HTMLLinkElement = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = faviconUrl;
    document.getElementsByTagName('head')[0].appendChild(link);
  }

  addCourseToCart(courses) {
    this._alertService.showWarningMessage(CART_WARNING.MESSAGE);
    this.activeSubscriptions.add(from(courses).pipe(
      concatMap(course => this._buyCourses.addToCart({ courseId: course['_id'], classId: course['class']?._id || 'null' })), toArray(), last()
    ).subscribe( res => {
        localStorage.removeItem('userCart');
        this.activeSubscriptions.add(
          this.store.select("userProfile").subscribe((userInfo) => {
            const redirectUrl = localStorage.getItem('redirectUrl');
            if(userInfo['userData']['userGoals'].length && redirectUrl){
              localStorage.removeItem('redirectUrl');
              this.router.navigateByUrl(redirectUrl);
            }
          })
        );
      }
    ));
  }

  checkHeaderCondition(event): void {
    const baseUrl = this.getBaseUrl(event.url);
    this.showHeader = this.routesToShowHeader.includes(baseUrl);
  }

  getBaseUrl(url: string): string {
    let urlData = url.split('?')[0].split('/');
    if (urlData.length >= this.baseURLMinimumLength) {
      url = '/' + urlData[1];
    }
    return url;
  }


  checkFooterCondition(event): void {

  }

  updateExamPopupDetails(): void {
    this.utilityService.getExamPopupDetails().subscribe((data: any) => {
      if (data?.data?.popUps) {
        this.originalExamPopupDetails = [...data.data.popUps];
        this.filterExamPopDetails();
      }
    });
  }

  filterExamPopDetails() {
    this.examPopupDetails = [... this.originalExamPopupDetails];
    this.numberOfPopups = this.examPopupDetails.length;
  }

  ngOnDestroy(): void {
    this.activeSubscriptions.unsubscribe();
  }
}
