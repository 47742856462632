import { USER_TYPE } from "./quiz-platform/constants/quiz.constants";
export const EDFORA_QUIZ_DOMAIN = 'quiz.edfora.com';
export const EDFORA_QUIZ_TITLE = 'Edfora - Education for All';
export const EDFORA_QUIZ_FAVICON = 'https://www.edfora.com/favicon.ico';
export const EDFORA_QUIZ_URL = `quiz/${USER_TYPE.ADVTSEPT_2}`;

export const ROUTES_LINK = {
    ACCOUNT_DELETION: 'user/account-deletion'
}
export const EDFORA_DOMAIN = '.edfora.com';
export const FACULTY_RECRUITMENT = 'facultyrecruitment';
export const EDFORA_QUIZ_URL_STPT_15 = `quiz/${USER_TYPE.ADVTSEPT_15}`;
