import { UtilityService } from '../../shared/services/utility.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { map, catchError, switchMap } from 'rxjs/operators';

import * as fromApp from '../app.reducers';
import * as UserProfileActions from './user-profile.actions';
import * as ProformaActions from '../proforma/proforma.actions';
import * as LoaderAction from '../../stores/loader/loader.actions';

import { UserProfile } from '../../models/profile.model';
import { HttpService } from '../../shared/services/http.service';
import { AlertService } from '../../shared/services/alert.service';
import { OnboardingService } from '../../user-onboarding/service/onboarding.service';
import { ProfileService } from '../../profile/shared/services/profile.service';
import { DetectLocationService } from '../../shared/services/detect-location.service';
import { ROUTES as ON_SPOT_ROUTES } from '../../on-spot-test/on-spot-test.constants';
import { getAdmissionTestRedirectionUrl } from '../../shared/utils/admissionTest.util';
import { USER_INFO_CONST } from 'src/app/shared/constants/common.constant';

interface ApiResponse {
    code: number;
    data: any;
    message?: any;
}

interface UserResponse {
    fromScreen: string;
}

@Injectable()
export class UserEffects {

    body;
    response;
    MSInputMethodContext;
    userData;
    constructor(
        private http: HttpService,
        private action$: Actions,
        private router: Router,
        private store: Store<fromApp.AppState>,
        private alertService: AlertService,
        private utilityService: UtilityService,
        private onboardingService: OnboardingService,
        private profileService: ProfileService,
        private detectLocation: DetectLocationService) {
        this.store.select('userProfile').subscribe((userProfile) => {
            this.userData = userProfile.userData;
        });
    }

    // Registering the effect of get user detail action
    @Effect()
    getUserDetails = this.action$.pipe(
        ofType<UserProfileActions.GetUserDetail>(UserProfileActions.GET_USER_DETAIL),
        switchMap((data) => {
            const fromScreen = data['fromScreen'];
            return this.http.get('v4/auth/user-info', true, null, true).pipe(
                map(
                    (res: ApiResponse) => {
                        if (res.code === 200) {
                            const userInfo = res.data;
                            if (userInfo.hasOwnProperty(USER_INFO_CONST.userAccessStatus) && userInfo.userAccessStatus === USER_INFO_CONST.restrictAccess) {
                             this.utilityService.restrictAccessPopUp.next(true);
                            } else {
                             this.profileService.showMyPlanPopUp.next(userInfo.showMyPlanPopUp);
                            }
                            localStorage.setItem('body', userInfo._id);
                            localStorage.setItem('enrollmentNo', userInfo.enrollmentNo);
                            localStorage.setItem('isNCRPUser',userInfo?.isNCRPUser);
                            !userInfo.isNCRPUser && this.utilityService.showApplicationSurveyPopup.next(true);
                            let initials = '';
                            if (res.data.name.hasOwnProperty('firstName') && res.data.name.firstName.length > 0) {
                                initials = this.utilityService.getYourInitials(res.data.name);
                            }
                            const initialsClass = this.utilityService.getInitialsClass();
                            const userTag = data.payload.tag ? data.payload.tag : 'student';
                            const teacherUrl = sessionStorage.getItem('teachersRedirectUrl');
                            if (userInfo?.isAdmissionTestUser && !userInfo?.onSpotRegNo) {
                                const redirectionUrl = getAdmissionTestRedirectionUrl(userInfo?.admissionTest);
                                this.router.navigate([redirectionUrl]);
                            }
                            if ((!teacherUrl || teacherUrl === '') && !userInfo?.isAdmissionTestUser) {
                                if (userInfo?.onboardingRequired && !userInfo.isTeacher && !userInfo?.dateOfBirth) {
                                    this.router.navigate(['/complete-profile']);
                                } 
                                else {
                                    if (!window.location.href.includes('parent-proforma') && !window.location.href.includes('teacher-proforma')) {
                                        if (window.location.href.split('/')[window.location.href.split('/').length - 1] === '/') {
                                            this.router.navigate(['/home']);
                                        } else {
                                            if (localStorage.getItem('lastRequested')) {
                                                localStorage.removeItem('lastRequested');
                                            } else {
                                                this.router.navigate(['/home']);
                                            }
                                        }
                                    }
                                }
                            }
                            let name = userInfo.name.firstName ? userInfo.name.firstName +
                                ' ' + (userInfo.name.middleName ? userInfo.name.middleName + ' ' : '') +
                                (userInfo.name.lastName ? userInfo.name.lastName : '') : '';
                            name = name.trim();
                            const user = new UserProfile(userInfo._id, name, userInfo.userName, (userInfo.mobile || (userInfo.b2bMobile && userInfo.b2bMobile.number)), userInfo.countryCode,
                                userInfo.email,userInfo.dateOfBirth, userInfo.goals, userInfo.class, userInfo.careers, userInfo.location, {}, userInfo.mypatEnrollmentNo,
                                '', userInfo.school, '', userInfo.photoUrl, false, false, userInfo.packages, 0, userInfo.passwordExists, {},
                                userInfo.referralCode, '', {}, '', null, (userInfo.userType === 'fiitjee'),
                                this.detectLocation.isSchoolDomain(), (userInfo.mobileIsVerified || (userInfo.b2bMobile && userInfo.b2bMobile.isVerified)), userInfo.emailIsverified,
                                userInfo.followerCount, userInfo.followingCount, initials, initialsClass, userInfo.centres, userInfo.enrollmentNo, userInfo.isNCRPUser, userInfo.featurePermissions, userInfo.isTeacher, userInfo.isAdmissionTestUser, userInfo.admissionTest, userInfo.hasActivePackage,
                                userInfo.onSpotRegNo, userInfo?.fiitjeeFacultyHiring
                            );
                            if (name && userInfo.userName) {
                                localStorage.setItem('step1Status', 'completed');
                            } else {
                                localStorage.removeItem('step1Status');
                            }
                            if (userInfo.class && userInfo.goals) {
                                localStorage.setItem('step2Status', 'completed');
                            } else {
                                localStorage.removeItem('step2Status');
                            }
                            if (userInfo.school.name) {
                                localStorage.setItem('step3Status', 'completed');
                            } else {
                                localStorage.removeItem('step3Status');
                            }
                            if (userInfo.photoUrl || (userInfo.onboardingStepsSkipped && userInfo.onboardingStepsSkipped.find(step => step.name === 'profilePicture'))) {
                                localStorage.setItem('step4Status', 'completed');
                            } else {
                                localStorage.removeItem('step4Status');
                            }
                            if ((userInfo?.mobileIsVerified || userInfo?.b2bMobile?.isVerified) && userInfo?.emailIsverified) {
                                localStorage.setItem('step5Status', 'completed');
                            } else {
                                localStorage.removeItem('step5Status');
                            }
                            return {
                                type: UserProfileActions.UPDATE_USER_DETAIL,
                                payload: user
                            };
                        } else {
                            return {
                                type: UserProfileActions.FAIL
                            };
                        }
                    }
                ),
                catchError((error) => {
                    return [
                        {
                            type: UserProfileActions.FAIL
                        }
                    ];
                })
            );
        }),
    );


    // Registering the effect of update user detail action
    @Effect({ dispatch: false })
    updateUserDetail = this.action$.pipe(
        ofType(UserProfileActions.UPDATE_USER_DETAIL),
        map((action: UserProfileActions.UpdateUserDetail) => {
            return action.payload;
        }),
    );

    // Update class of the user in the store
    @Effect({ dispatch: false })
    addClass = this.action$.pipe(
        ofType(UserProfileActions.ADD_CLASS),
        map((action: UserProfileActions.AddClass) => {
            return action.payload;
        }),
    );

    // Update career of the user in the store
    @Effect({ dispatch: false })
    addCareer = this.action$.pipe(
        ofType(UserProfileActions.ADD_CAREER),
        map((action: UserProfileActions.AddCareer) => {
            return action.payload;
        }),
    );

    // Registering the effect of add goal action
    @Effect()
    addGoal = this.action$.pipe(
        ofType<UserProfileActions.AddGoal>(UserProfileActions.ADD_GOAL),
        map((action: UserProfileActions.AddGoal) => {
            return action.payload;
        }),
        switchMap(
            (UserDetail) => {
                return this.http.put('v4/auth/user', true, UserDetail, true).pipe(
                    map(
                        (res: ApiResponse) => {
                            if (res && res.code === 200) {
                                const careerToAdd = [];
                                // tslint:disable-next-line: prefer-for-of
                                for (let i = 0; i < UserDetail['careers'].length; i++) {
                                    careerToAdd.push({
                                        id: UserDetail['careers'][i].id,
                                        name: UserDetail['careers'][i].name
                                    });
                                }
                                if (this.router.url.includes('complete-profile')) {
                                    localStorage.setItem('step2Status', 'completed');
                                    this.onboardingService.currentStep.next('3');
                                    this.onboardingService.lastStep.next('2c');
                                    this.router.navigateByUrl('complete-profile/3');
                                } else {
                                    this.profileService.editedCourseDetails.next(true);
                                }
                                return {
                                    type: UserProfileActions.ADD_GOAL_SUCCESS,
                                    // tslint:disable-next-line: no-string-literal
                                    payload: {
                                        userClass: UserDetail['userClass'],
                                        goals: UserDetail['goals'],
                                        userCareers: careerToAdd
                                    },
                                };
                            } else if (res.code === 400) {
                                this.store.dispatch(new LoaderAction.SetLoader(false));
                                this.alertService.showErrorMessage(res.message);
                            } else {
                                this.store.dispatch(new LoaderAction.SetLoader(false));
                                this.alertService.showErrorMessage(res.message);
                            }
                            return {
                                type: UserProfileActions.FAIL
                            };
                        }),
                    catchError((error) => {
                        return [
                            {
                                type: UserProfileActions.FAIL
                            }
                        ];
                    }));
            }
        )
    );


    // Adding name in case of add name + username

    @Effect()
    addName = this.action$.pipe(
        ofType<UserProfileActions.AddName>(UserProfileActions.ADD_NAME),
        map((action: UserProfileActions.AddName) => {
            return action.payload;
        }),
        switchMap(
            (UserDetail) => {
                const dataToSend = {
                    // tslint:disable-next-line: no-string-literal
                    name: UserDetail['name'],
                    // tslint:disable-next-line: no-string-literal
                    userName: UserDetail['userName']
                };
                return this.http.put('v4/auth/user-name', true, dataToSend, true).pipe(
                    map(
                        (res: ApiResponse) => {
                            if (res && res.code === 200) {
                                localStorage.setItem('step1Status', 'completed');
                                if (UserDetail['isFiitjee']) {
                                    this.onboardingService.currentStep.next('3');
                                    this.onboardingService.lastStep.next('2c');
                                    this.router.navigateByUrl('complete-profile/3');
                                } else {
                                    this.onboardingService.currentStep.next('2a');
                                    this.onboardingService.lastStep.next('1');
                                    this.router.navigateByUrl('complete-profile/2');
                                }
                                return {
                                    type: UserProfileActions.ADD_NAME_SUCCESS,
                                    // tslint:disable-next-line: no-string-literal
                                    payload: { name: dataToSend['name'], userName: dataToSend['userName'] },
                                };
                            } else if (res.code === 400) {
                                this.store.dispatch(new LoaderAction.SetLoader(false));
                                this.onboardingService.errorMessage.next({ step1: res.message });
                            } else {
                                this.store.dispatch(new LoaderAction.SetLoader(false));
                                this.alertService.showErrorMessage(res.message);
                            }
                            return {
                                type: UserProfileActions.FAIL
                            };
                        }),
                    catchError((error) => {
                        return [
                            {
                                type: UserProfileActions.FAIL
                            }
                        ];
                    }));
            }
        )
    );

    // Registering the effect of add school action
    @Effect()
    addSchool = this.action$.pipe(
        ofType<UserProfileActions.AddSchool>(UserProfileActions.ADD_SCHOOL),
        map((action: UserProfileActions.AddSchool) => {
            return action.payload;
        }),
        switchMap(
            (UserDetail) => {
                return this.http.put('v4/auth/user', true, UserDetail, true).pipe(
                    map(
                        (res: ApiResponse) => {
                            if (res && res.code === 200) {
                                if (this.router.url.includes('complete-profile')) {
                                    localStorage.setItem('step3Status', 'completed');
                                    this.onboardingService.currentStep.next('4');
                                    this.onboardingService.lastStep.next('3');
                                    this.router.navigateByUrl('complete-profile/4');
                                    this.onboardingService.schoolUpdated.next(false);
                                } else {
                                    this.profileService.schoolAdded.next(true);
                                }
                                const payload = {
                                    fromScreen: 'step3',
                                    authToken: localStorage.getItem('authToken') || sessionStorage.getItem('authToken'),
                                    tag: ''
                                  };
                                return {
                                    type: UserProfileActions.GET_USER_DETAIL,
                                    payload: payload
                                };
                            } else if (res.code === 400) {
                                this.store.dispatch(new LoaderAction.SetLoader(false));
                                this.alertService.showErrorMessage(res.message);
                            } else {
                                this.store.dispatch(new LoaderAction.SetLoader(false));
                                this.alertService.showErrorMessage(res.message);
                            }
                            return {
                                type: UserProfileActions.FAIL
                            };
                        }),
                    catchError((error) => {
                        return [
                            {
                                type: UserProfileActions.FAIL
                            }
                        ];
                    }));
            }
        )
    );


    // Registering the effect of add profile action
    @Effect()
    addPhoto = this.action$.pipe(
        ofType<UserProfileActions.AddProfileSuccess>(UserProfileActions.ADD_PROFILE_SUCCESS),
        map((action: UserProfileActions.AddProfileSuccess) => {
            return action.payload;
        }),
        switchMap(
            (UserDetail) => {
                return this.http.put('v4/auth/user', true, UserDetail, true).pipe(
                    map(
                        (res: ApiResponse) => {
                            if (res && res.code === 200) {
                                localStorage.setItem('step4Status', 'completed');
                                this.onboardingService.currentStep.next('5a');
                                this.onboardingService.lastStep.next('4');
                                this.router.navigateByUrl('complete-profile/5');
                                return {
                                    type: UserProfileActions.ADD_PROFILE_SUCCESS,
                                    // tslint:disable-next-line: no-string-literal
                                    payload: UserDetail['photoUrl']
                                };
                            } else if (res.code === 400) {
                                this.store.dispatch(new LoaderAction.SetLoader(false));
                                this.alertService.showErrorMessage(res.message);
                            } else {
                                this.store.dispatch(new LoaderAction.SetLoader(false));
                                this.alertService.showErrorMessage(res.message);
                            }
                            return {
                                type: UserProfileActions.FAIL
                            };
                        }),
                    catchError((error) => {
                        return [
                            {
                                type: UserProfileActions.FAIL
                            }
                        ];
                    }));
            }
        )
    );

    // Registering the effect of verifing mobile  action
    @Effect()
    verifyMobile = this.action$.pipe(
        ofType(UserProfileActions.VERIFY_MOBILE_PROFILE),
        map((action: UserProfileActions.VerifyMobileProfile) => {
            return action.payload;
        }),
        switchMap(
            (payload) => {
                return this.http.put('v4/auth/users/verify-otp', true, payload, true).pipe(
                    map(
                        (res: ApiResponse) => {
                            if (res.code === 200) {
                                if (this.router.url.includes('complete-profile')) {
                                    this.onboardingService.phoneAdded.next('true');
                                    // localStorage.setItem('step5Status', 'completed');
                                    this.onboardingService.currentStep.next('5b');
                                    this.onboardingService.lastStep.next('5a');
                                } else {
                                    this.profileService.emailMobileAdded.next({ mobileAdded: true });
                                }
                                return {
                                    type: UserProfileActions.VERIFY_MOBILE_PROFILE_SUCCESS,
                                    payload: { mobile: payload.mobile, countryCode: payload.countryCode },
                                };
                            } else if (res.code === 202 && this.router.url.includes('edit')) {
                            } else if (res && res.code === 409) {
                            } else {
                                this.alertService.showErrorMessage(res.message);
                                return {
                                    type: UserProfileActions.FAIL
                                };
                            }
                            return {
                                type: UserProfileActions.FAIL
                            };
                        }
                    ),
                    catchError((error) => {
                        return [
                            {
                                type: UserProfileActions.FAIL
                            }
                        ];
                    }));
            }
        )
    );

    // Registering the effect of verifing mobile  action
    @Effect()
    verifyEmail = this.action$.pipe(
        ofType(UserProfileActions.VERIFY_EMAIL_PROFILE),
        map((action: UserProfileActions.VerifyEmailProfile) => {
            return action.payload;
        }),
        switchMap(
            (payload) => {
                return this.http.put('v4/auth/users/verify-otp', true, payload, true).pipe(
                    map(
                        (res: ApiResponse) => {
                            if (res.code === 200 || res.code === 408) {
                                if (this.router.url.includes('complete-profile')) {
                                    localStorage.setItem('step5Status', 'completed');
                                    this.onboardingService.currentStep.next('6');
                                    this.onboardingService.lastStep.next('5b');
                                    this.router.navigateByUrl('complete-profile/6');
                                } else {
                                    this.profileService.emailMobileAdded.next({ emailAdded: true });
                                }
                                return {
                                    type: UserProfileActions.VERIFY_EMAIL_PROFILE_SUCCESS,
                                    payload: { email: payload.email }
                                };
                            } else {
                                this.alertService.showErrorMessage(res.message);
                            }
                            return {
                                type: UserProfileActions.FAIL
                            };
                        }
                    ),
                    catchError((error) => {
                        return [
                            {
                                type: UserProfileActions.FAIL
                            }
                        ];
                    }));
            }
        )
    );

    // Registering the effect of add password action
    @Effect()
    addPassword = this.action$.pipe(
        ofType(UserProfileActions.ADD_PASSWORD),
        map((action: UserProfileActions.AddPassword) => {
            return action.payload;
        }),
        switchMap(
            (payload) => {
                return this.http.post('v4/auth/reset-password', true, payload, true).pipe(
                    map(
                        (data: ApiResponse) => {
                            if (data.code === 200) {
                                this.http.put('v4/auth/users/mypat-enrolment-number', true, {}, true).subscribe((res) => {
                                    if (res && res.code === 200) {
                                        this.store.dispatch(new LoaderAction.SetLoader(false));
                                        localStorage.setItem('step6Status', 'completed');
                                        this.onboardingService.currentStep.next('6');
                                        this.onboardingService.lastStep.next('6');
                                        const payload = {
                                            fromScreen: 'refresh',
                                            authToken: localStorage.getItem('authToken') || sessionStorage.getItem('authToken'),
                                            tag: 'student'
                                        };
                                        this.store.dispatch(new ProformaActions.GetProformaDetail(payload));
                                    } else {
                                        this.alertService.showErrorMessage(res.message);
                                    }
                                });
                                return {
                                    type: UserProfileActions.ADD_PASSWORD_SUCCESS,
                                    payload: {}
                                };
                            } else {
                                return {
                                    type: UserProfileActions.FAIL
                                };
                            }
                        }
                    ),
                    catchError((error) => {
                        return [
                            {
                                type: UserProfileActions.FAIL
                            }
                        ];
                    }));
            }
        )
    );

    @Effect()
    skipPassword = this.action$.pipe(
        ofType(UserProfileActions.SKIP_PASSWORD),
        map((action: UserProfileActions.SkipPassword) => {
            return action.payload;
        }),
        switchMap(() => {
            return this.http.put('v4/auth/users/mypat-enrolment-number', true, {}, true).pipe(
                map((res: ApiResponse) => {
                    if (res && res.code === 200) {
                        this.store.dispatch(new LoaderAction.SetLoader(false));
                        localStorage.setItem('step6Status', 'completed');
                        this.onboardingService.currentStep.next('6');
                        this.onboardingService.lastStep.next('6');

                        const payload = {
                            fromScreen: 'refresh',
                            authToken: localStorage.getItem('authToken') || sessionStorage.getItem('authToken'),
                            tag: 'student'
                        };
                        this.store.dispatch(new ProformaActions.GetProformaDetail(payload));
                        // this.router.navigateByUrl('dashboard');
                        return {
                            type: UserProfileActions.SKIP_PASSWORD_SUCCESS,
                            payload: {}
                        };
                    } else {
                        return {
                            type: UserProfileActions.FAIL
                        };
                    }
                }),
                catchError((error) => {
                    return [
                        {
                            type: UserProfileActions.FAIL
                        }
                    ];
                })
            )
        }
        ),
    );


    // Registering the effect to get class list action
    @Effect()
    getClassList = this.action$.pipe(
        ofType(UserProfileActions.GET_CLASS_LIST),
        map((action: UserProfileActions.GetClassList) => {
            return;
        }),
        switchMap(
            (obj) => {
                if (this.router.url.includes('user-profile-completion')) {
                    localStorage.setItem('fetchedClassList', 'true');
                }
                return this.http.get('getClass', false, null, true),
                    catchError((error) => {
                        return [
                            {
                                type: UserProfileActions.FAIL
                            }
                        ];
                    });
            }
        ),
        map((res: ApiResponse) => {
            if (res.code === 200) {
                return {
                    type: UserProfileActions.FAIL
                };
            }
            else {
                return {
                    type: UserProfileActions.FAIL
                };
            }
        }
        )
    );

    // Registering the effect to get goal list action
    @Effect()
    getGoalList = this.action$.pipe(
        ofType(UserProfileActions.GET_GOAL_LIST),
        map((action: UserProfileActions.GetGoalList) => {
            return action.payload;
        }),
        switchMap(
            (className) => {
                return this.http.get('getGoals?class=' + className, false, null, true),
                    catchError((error) => {
                        return [
                            {
                                type: UserProfileActions.FAIL
                            }
                        ];
                    });
            }
        ),
        map((res: ApiResponse) => {
            if (res.code === 200) {
                if (res && res.data && res.data.length) {
                }
                return {
                    type: UserProfileActions.FAIL
                };
            } else {
                return {
                    type: UserProfileActions.FAIL
                };

            }
        }
        )
    );

    @Effect()
    addReferral = this.action$.pipe(
        ofType(UserProfileActions.ADD_REFERRAL),
        map((action: UserProfileActions.AddReferral) => {
            return action.payload;
        }),
        switchMap(
            (obj) => {
                const dataToSend = null;
                return this.http.put('updateOtherDetails', true, dataToSend, true),
                    map(
                        (data: ApiResponse) => {
                            if (data.code === 200) {
                                return {
                                    type: UserProfileActions.ADD_REFERRAL_SUCCESS,
                                    payload: obj
                                };
                            } else {
                                return {
                                    type: UserProfileActions.FAIL
                                };
                            }
                        }),
                    catchError((error) => {
                        return [
                            {
                                type: UserProfileActions.FAIL
                            }
                        ];
                    });
            }
        )
    );


    // Updating user profile photo from user card section - profile details
    @Effect()
    updateUserProfilePhoto = this.action$.pipe(
        ofType<UserProfileActions.UpdateUserCardProfile>(UserProfileActions.UPDATE_USER_CARD_PROFILE),
        map((action: UserProfileActions.UpdateUserCardProfile) => {
            return action.payload;
        }),
        switchMap(
            (UserDetail) => {
                return this.http.put('v4/auth/user', true, UserDetail, true).pipe(
                    map(
                        (res: ApiResponse) => {
                            if (res && res.code === 200) {
                                this.store.dispatch(new LoaderAction.SetLoader(false));
                                this.alertService.showSuccessMessage(res.message);
                                return {
                                    type: UserProfileActions.UPDATE_USER_CARD_PROFILE_SUCCESS,
                                    payload: {
                                        // tslint:disable-next-line: no-string-literal
                                        photoUrl: UserDetail['photoUrl']
                                    }
                                };
                            } else if (res.code === 400) {
                                this.store.dispatch(new LoaderAction.SetLoader(false));
                                this.alertService.showErrorMessage(res.message);
                            } else {
                                this.store.dispatch(new LoaderAction.SetLoader(false));
                                this.alertService.showErrorMessage(res.message);
                            }
                            return {
                                type: UserProfileActions.FAIL
                            };
                        }),
                    catchError((error) => {
                        return [
                            {
                                type: UserProfileActions.FAIL
                            }
                        ];
                    }));
            }
        )
    );
}
