import { Injectable } from '@angular/core';
import { HttpService } from '../services/http.service';
import { AlertService } from './alert.service';
import { Subject, BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Title, Meta, DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import * as fromApp from '../../stores/app.reducers';
import { HttpClient } from '@angular/common/http';
import { UserProfile } from 'src/app/models/profile.model';
@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  private globalPopupStatus: boolean = false;
  public countryArray: any = [];
  public freeTrialDataContainer = new BehaviorSubject<any>({});
  public changePasswordResolver = new BehaviorSubject<any>({});
  public testResultUrlParams = new BehaviorSubject<any>({});
  public emptyDataRevision = new BehaviorSubject<any>({});
  public hideMainCalendar = new Subject<boolean>();
  public showApplicationSurveyPopup = new BehaviorSubject<any>(false);
  public otpPopupHandler = new Subject<any>();
  public captchaStateHandler = new BehaviorSubject<any>(false);
  public globalAuthPopupNeeded = new Subject<boolean>();
  public signUpSuccess = new BehaviorSubject<any>({});
  public signUpForcefully = new Subject<any>();
  public signupData = new BehaviorSubject<any>(null);
  public closePopUp = new BehaviorSubject<any>(null);
  public isNewUser = new BehaviorSubject<any>(null);
  public downloadEbook = new BehaviorSubject<any>(false);
  public userIdFetch = new BehaviorSubject<string>('');
  private collegePredData = {};
  public restrictAccessPopUp = new BehaviorSubject<boolean>(false);
  public sidebarExpandedSubject = new BehaviorSubject<boolean>(false);
  sidebarExpanded$ = this.sidebarExpandedSubject.asObservable();

  constructor(private sanitizer: DomSanitizer, private httpService: HttpClient, private http: HttpService, private alertService: AlertService, private titleService: Title, private metaService: Meta,
    private store: Store<fromApp.AppState>) {
  }

  get getCollegePred() {
    return this.collegePredData;
  }

  public setCollegePred(data) {
    this.collegePredData = { ...data };
  }

  setMainCalendar(value) {
    this.hideMainCalendar.next(value)
  }

  getAllCountryList(): any {

    this.countryArray = [
      {
        alpha3: 'IND',
        name: 'India',
        dialCode: '+91',
        class: 'India',
        regex: '^[6-9]\\d{9}$',
        flag: '../../../assets/images/country-flags/india.png'
      },
      {
        alpha3: 'NPL',
        name: 'Nepal',
        dialCode: '+977',
        class: 'Nepal',
        regex: '^[1-9]\\d{9}$',
        flag: '../../../assets/images/country-flags/nepal.png'
      },
      {
        alpha3: 'SAU',
        name: 'Saudi Arabia',
        dialCode: '+966',
        class: 'SaudiArabia',
        regex: '^(5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$',
        flag: '../../../assets/images/country-flags/saudi-arabia.png'
      },
      {
        alpha3: 'MYS',
        name: 'Malaysia',
        dialCode: '+60',
        class: 'Malaysia',
        regex: '[1][0123456789][0-9]{5}',
        flag: '../../../assets/images/country-flags/malaysia.png'
      },
      {
        alpha3: 'ARE',
        name: 'United Arab Emirates',
        dialCode: '+971',
        class: 'UnitedArabEmirates',
        regex: '^(5[02468]{1})[1-9]{1}[0-9]{6}$',
        flag: '../../../assets/images/country-flags/united-arab-emirates.png'
      },
      {
        alpha3: 'MMR',
        name: 'Myanmar',
        dialCode: '+95',
        class: 'Myanmar',
        regex: '[9][0-9]{9}',
        flag: '../../../assets/images/country-flags/myanmar.png'
      },
      {
        alpha3: 'LKA',
        name: 'Sri Lanka',
        dialCode: '+94',
        class: 'SriLanka',
        regex: '[7][0-9]{6}',
        flag: '../../../assets/images/country-flags/sri-lanka.png'
      },
      {
        alpha3: 'OMN',
        name: 'Oman',
        dialCode: '+968',
        class: 'Oman',
        regex: '[9]\\d[0-9]{6}$',
        flag: '../../../assets/images/country-flags/oman.png'
      },
      {
        alpha3: 'SGP',
        name: 'Singapore',
        dialCode: '+65',
        class: 'Singapore',
        regex: '[689]\\d[0-9]{7}$',
        flag: '../../../assets/images/country-flags/singapore.png'
      },
      {
        alpha3: 'BHR',
        name: 'Bahrain',
        dialCode: '+973',
        class: 'Bahrain',
        regex: '(31|33|36|39|322|340|341|342|343|344|345|353|377|383|384|388|663|666|669){1}[0-9]{5}',
        flag: '../../../assets/images/country-flags/bahrain.png'
      },
      {
        alpha3: 'QAT',
        name: 'Qatar',
        dialCode: '+974',
        class: 'Qatar',
        regex: '[3-9]{1}[0-9]{7}$',
        flag: '../../../assets/images/country-flags/qatar.png'
      },
      {
        alpha3: 'ZAF',
        name: 'South Africa',
        dialCode: '+27',
        class: 'SouthAfrica',
        regex: '[0]\\d[1-9]{9}$',
        flag: '../../../assets/images/country-flags/south-africa.png'
      },
      {
        alpha3: 'USA',
        name: 'United States Of America',
        dialCode: '+1',
        class: 'UnitedStates',
        regex: '^([2-9]{3})[0-9]{3}[0-9]{4}$',
        flag: '../../../assets/images/country-flags/united-states-of-america.png'
      },
      // {
      //   alpha3: 'KWT',
      //   name: 'Kuwait',
      //   dialCode: '+965',
      //   class: 'Kuwait',
      //   regex: '[569]\\d{7}$'
      // },
    ];
    return this.countryArray;
  }

  getAllClasses(): any {
    return new Promise((resolve, reject) => {
      this.http.get('v4/classes', false, null, true).subscribe((res) => {
        if (res && res.code) {
          resolve(res);
        }
      });
    });
  }

  fetchCaptchaDetails(): any {
    return new Promise((resolve, reject) => {
      this.http.get('v4/check-if-captcha-show', false, null, false).subscribe((res) => {
        if (res && res.code) {
          resolve(res);
        }
      });
    });
  }

  fetchFreeTrialDataAPI(): any {
    return this.http.get('api/v3/user/freeTrial', true, null, false, false, environment.serverURLv3).subscribe(
      response => {
        if (response.code === 200) {
          const responseReturn = response.data;
          responseReturn.serverTime = response.serverTime;
          this.freeTrialDataContainer.next(responseReturn);
        } else {
          this.alertService.showErrorMessage(response.message);
        }
      }
    );
  }

  sendOTP(data): any {
    return new Promise((resolve, reject) => {
      this.http.post('v4/auth/users/send-otp', true, data).subscribe((res) => {
        if (res && res.code === 200) {
          resolve(res);
          this.alertService.showSuccessMessage(res.message);
        } else {
          this.alertService.showErrorMessage(res.message);
        }
      });
    });
  }

  fetchScheduleData(data): any {
    return new Promise((resolve, reject) => {
      this.http.get('scheduleDetail', true, data, true, false, environment.serverURLv3).subscribe((res) => {
        if (res && res.code === 200) {
          resolve(res);
        } else {
          this.alertService.showErrorMessage(res.message);
        }
      });
    });
  }

  getYourInitials(name): string {
    name.firstName = name.firstName.trim();
    if (name.firstName.split(' ').length > 1) {
      let initials = name.firstName.match(/\b\w/g) || [];
      initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
      return initials;
    } else {
      const initials = name.firstName[0] + name.firstName[1];
      return initials.toUpperCase();
    }
  }

  getTwoCharsIcon(achievementName): string {
    let getInitialTwoWords = ""
    getInitialTwoWords = achievementName[0] + achievementName[1]
    return getInitialTwoWords.toUpperCase();
  }


  getInitialsClass(): string {
    const classArray = ['alphabet-bg1', 'alphabet-bg2', 'alphabet-bg3', 'alphabet-bg4', 'alphabet-bg5'];
    const randomNumber = Math.floor(Math.random() * classArray.length);
    return classArray[randomNumber];
  }

  setTitleAndMetaDesc(title: string, desc: string): void {
    // Changing title
    this.titleService.setTitle(title);
    // Changing meta with name="description"
    const tag = { name: 'description', content: desc };
    const attributeSelector = 'name="description"';
    this.metaService.removeTag(attributeSelector);
    this.metaService.addTag(tag, false);
  }

  public addFacebookGraphTags(url, title, type): void {
    const tags = [
      { name: 'og:url', property: 'og:url', content: url },
      { name: 'og:title', property: 'og:title', content: title },
      { name: 'og:type', property: 'og:type', content: type },
    ];
    this.metaService.removeTag('name="og:url"');
    this.metaService.removeTag('name="og:title"');
    this.metaService.removeTag('name="og:type"');
    this.metaService.addTags(tags, true);
  }

  loadScript(): void {
    let isFound = false;
    const scripts = document.getElementsByTagName('script');
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < scripts.length; ++i) {
      if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes('carousel-menu')) {
        isFound = true;
      }
    }
    if (!isFound) {
      const dynamicScripts = ['../../assets/js/carousel-menu.js'];
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < dynamicScripts.length; i++) {
        const node = document.createElement('script');
        node.src = dynamicScripts[i];
        node.type = 'text/javascript';
        node.async = false;
        node.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(node);
      }
    }
  }

  getUrlParamsFromResult(res, routeVal): void {
    const storeData = {};
    let course;
    this.store.select('userProfile').subscribe(resNew => {
      if (resNew && resNew.selectedGoal) {
        course = resNew.selectedGoal['courseId'];
      }
    });

    storeData['attemptId'] = res.attemptId ? res.attemptId : null;
    storeData['testId'] = res.testId ? res.testId : null;
    storeData['testType'] = res.testType ? res.testType : null;
    storeData['testName'] = res.testName ? res.testName : null;
    storeData['authToken'] = res.authToken ? res.authToken : null;
    storeData['courseId'] = res.courseId ? res.courseId : course ? course : null;
    storeData['pageLoaded'] = routeVal;
    storeData['nonSeriousAttempt'] = res.nonSeriousAttempt;
    this.testResultUrlParams.next(storeData);
  }

  debounce(func, wait, immediate = false) {
    let timeout;
    return function () {
      let context = this, args = arguments;
      let later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      let callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  };

  getExamPopupDetails() {
    return this.http.get('exam-popup-details', true, null, null, null, environment.serverURLv3);
  }

  getImageSignedUrl(params?: any) {
    const url = `aws/presigned-url?type=${params.type}&key=${params.key}`;
    return this.http.get(url, true, null, null, null, environment.serverURLv3);
  }

  submitApplicationSurvey(params) {
    return this.http.put('update-exam-details', true, params, true, environment.serverURLv3);
  }
  getTestSyllabusDetails (payload: any) {
    let url=`v4/auth/tests/${payload.testId}/details?courseId=${payload.courseId}&packageId=${payload.packageId}`
    if(payload.testId === '0'.repeat(24) && payload.scheduleID ){
      url += `&scheduleID=${payload.scheduleID}`
    }
    return this.http.get(url, true, {}, true, false, environment.serverURLv4);
  }

  createUserObject(userData) {
    if (userData.profile || (userData.userId && userData.userId._id)) {
      const { profile, userId } = userData;
      const { dateOfBirth } = userId || '';

      const userObj = profile || userData;
      const userRemarkObj = (userId && userId.remarks) || [];

      const partner = {
        isVerified: false,
        userName: null,
        partnerInstitute: null,
        isPartner: userObj.partner?.partnerUser === false ? 'no' : null,
        partnerGoal: [],
        enroll: null
      };

      if (userObj.partner && userObj.partner.data) {
        const { data, goals, partnerUser } = userObj.partner;
        partner.isVerified = data.isVerified;
        partner.userName = data.username || null;
        partner.partnerInstitute = userObj.partner.name || null;
        partner.enroll = data.enrolmentNo || null;
        partner.partnerGoal = goals || [];
        partner.isPartner = data.isVerified ? 'yes' : partner.isPartner;
      }

      const remarkData = {
        isBlocked: userRemarkObj.length && userRemarkObj[userRemarkObj.length - 1].status || '',
        blockReason: userRemarkObj.length && userRemarkObj[userRemarkObj.length - 1].blockReason || '',
        endTime: userRemarkObj.length && userRemarkObj[userRemarkObj.length - 1].blockDuration?.endTime || '',
        startTime: userRemarkObj.length && userRemarkObj[userRemarkObj.length - 1].blockDuration?.startTime || '',
      };

      const {
        _id,
        name: { firstName },
        mobile,
        email,
        goals,
        class: userClass,
        location,
        webAccessCode,
        school,
        photoUrlThumb,
        photoUrl,
        packageBought,
        goalDDCount,
        isPassword,
        tour,
        referralCode,
        parentAccessToken,
        isStepUser,
        refferedByCode,
        orgCentre,
      } = userObj.userId;

      const user = new UserProfile(
        _id,
        firstName,
        mobile,
        email,
        goals,
        userClass || userObj.userClass,
        location,
        partner,
        partner.enroll,
        webAccessCode,
        school,
        photoUrlThumb,
        photoUrl,
        null,
        null,
        packageBought,
        goalDDCount,
        isPassword,
        tour,
        referralCode,
        parentAccessToken,
        isStepUser,
        remarkData,
        refferedByCode,
        orgCentre,
        dateOfBirth
      );

      return user;
    }
  }


  getClasses() {
    return this.http.get("getClass?startingClass=9", false, {}, null, null, environment.serverURLv3);
  }

  verifyOTP(params: any) {
    return this.http.get('verifyOtpMobile', false, params, null, null, environment.serverURLv3);
  }

  resendOTP(params: any) {
    return this.http.post('sendOtpMobile', false, params, false, false, false, environment.serverURLv3);
  }

  setGlobalAuthPopup(status) {
    this.globalPopupStatus = status;
    this.globalAuthPopupNeeded.next(status);
  }

  getGlobalAuthPopup() {
    return this.globalPopupStatus;
  }

  getUserID(data){
    return this.http.post('verifyOtpMobile', false, data, false, false, false, environment.serverURLv3);
  }

  getAllAvailablePackages() {
    return this.http.get('packages?flag=all', false, {}, null, null, environment.serverURLv3);
  }
  toggleSidebar() {
    const currentValue = this.sidebarExpandedSubject.value;
    this.sidebarExpandedSubject.next(!currentValue);
  }
}
