export const environment = {
  production: true,
  baseHref: '/',
  myPatUrl: 'https://mypat.in',
  myPatUrlv4: 'https://mypat.in',
  fiitjeeUrlv3: 'https://fiitjee-v3.mypat.in',
  fiitjeeUrlv4: 'https://fiitjee.mypat.in',
  serverURLv3: 'https://v2api2-prod.mypat.in/',
  edforaURL: 'https://backend.edfora.com/',
  serverURLv4: 'https://v4-auth-service.mypat.in/',
  mockServerURL: '',
  fbAppId: '432998907135563',
  google_clientId: '544027600908-fl76h9b7sq949f3m9co5pnr9805nd61a.apps.googleusercontent.com',
  partnerDomain: 'https://fiitjee.mypat.in/auth/admin-access/',
  razorPayKey: 'rzp_live_tEr4IjWx6QfbwV',
  clevertapId: '698-8W8-545Z',
  kaptureCrmKey : 'Basic aDM3b2FtcmtjYzY1dHhzbzl6bDFmM2hoNnNodWF2cnNsenM5bGJuaGN1M3NqYnowdGg=',
  name: 'prod' ,
  captchaKey : '6LdURVEUAAAAADSnZK0cJacinv-PqlTO_7K-CQkU',
  zestMoneyMerchantId: '89cad954-b7ea-47a9-af2d-8c9a0027357f',
  zestMoneyPaymentGatewayId: '31a2b66b-f364-48f9-a7fd-6870a5a224b4',
  teacherSiteURL: 'https://schools-teacher.mypat.in/login',
  schoolPartnerDomain: 'https://schools.mypat.in/',
  teacherServerURL: 'https://v2enterprise-api.mypat.in/',
  s3BucketBaseUrl: 'https://s3.us-west-2.amazonaws.com/mypat-v2-staging/',
  glorifireCommunity: 'https://community.glorifire.com',
  glorifireliveClasses: 'https://liveclasses.glorifire.com',
  gtmId: 'GTM-NHSSJKV',
  androidURL: 'https://play.google.com/store/apps/details?id=com.mypat',
  iosURL: '',
  youTube:'https://youtube.com/@myPATlearning?si=X90qIGlwgEDOG0jps',
  instagram:'https://www.instagram.com/mypat_india/',
  linkedIn:'https://www.linkedin.com/company/mypat---my-performance-analysis-test?report%2Esuccess=KJ_KkFGTDCfMt-A7wV3Fn9Yvgwr02Kd6AZHGx4bQCDiP6-2rfP2oxyVoEQiPrcAQ7Bf',
  twitter:'https://twitter.com/myPAT_india',
  facebook:'https://www.facebook.com/pages/MyPatin/249818108402322',
  blog:'https://blog.mypat.in/',
  glorifireLite: 'https://mypat.in/',

};
